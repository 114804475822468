import * as React from "react"
import Layout from "../../components/layout"
import styled from 'styled-components'
import Hero from '../../components/hero'
import { AdContainer } from '../../components/adstyling'
import AdBanner from '../../components/adbanner'
import { Helmet } from "react-helmet"



// styles
const MainBodySection = styled.article`
  max-width: 960px;
  margin: 0 auto 50px;

  > p {
    color: #b13a04;
    max-width: 600px;
    margin: 30px auto 30px auto;
    line-height: 24px;

    @media only screen and (max-width: 900px) {
      padding: 0 20px;
    }
  }
}
`;

const TopLevelGrid = styled.div`

  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;

  > div {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 0.8em 1.2em;
    list-style: none;
    border-bottom: 1px solid #a34115;
    width: 40%;
    font-family: 'Source Sans Pro';
    &:nth-of-type(2n) {
      width: 60%;
    }

    h3 {
      color: #441600;
      font-size: 18px;
    }
  }
`;

const OperaGeneral = styled.div`
  h4 {
    font-size: 20px;
    margin-bottom: 0px;
  }

`;

const Acts = styled.div`
  h4 {
    font-size: 20px;
  }

  span {
    margin-top: 20px;
    width: 120px;
    display: inline-block;
    border-right: 1px solid #a34115;
    text-align: center;

    &:first-of-type {
      margin-left: -40px;
    }

    &:last-of-type {
      border: none;
    }

    @media only screen and (max-width: 800px) {
      display: block;
      border-bottom: 1px solid #a34115;
      border-right: none;
      padding-bottom: 10px;
      margin-top: 10px;

      &:last-of-type {
        border: none;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

const FilterGroup = styled.div`
  display: flex;
  justify-content: center;
`

const FilterSection = styled.div`
  text-align: center;
  margin: 20px;

  input {
    box-sizing: border-box;
    width: 200px;
    min-height: 30px;
    height: 30px;
    padding-left: 5px;
    background-color: #fbf7e4;
    box-shadow: none;
    border: 1px solid #441600;
    border-radius: 3px;
  }

  select {
    box-sizing: border-box;
    width: 140px;
    min-height: 30px;
    height: 30px;
    padding-left: 5px;
    background-color: #fbf7e4;
    box-shadow: none;
    border: 1px solid #441600;
    border-radius: 3px;
  }
`;


const OPERAS = [
  {'language': 'English', 'opera': "4.48 Psychosis", 'totaltime': '1:30', total: 90, 'composer': 'Philip Venables', 'times': [{'acttime': 90, 'actname': 'Opera'}]},
  {'language': 'Russian', 'opera': "A Dog's Heart", 'totaltime': '2:15', total: 135, 'composer': 'Alexander Raskatov', 'times': [{'acttime': 70, 'actname': 'Act I'}, {'acttime': 65, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': "A Midsummer Night's Dream", 'totaltime': '2:35', total: 155,'composer': 'Benjamin Britten', 'times': [{'acttime': 50, 'actname': 'Act I'}, {'acttime': 50, 'actname': 'Act II'}, {'acttime': 55, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'Acis and Galatea', 'totaltime': '1:30', total: 90, 'composer': 'G. F. Handel', 'times': [{'acttime': 90, 'actname': 'Act I'}]},
  {'language': 'English', 'opera': 'Acquanetta', 'totaltime': '1:10', total: 70, 'composer': 'Michael Gordon', 'times': [{'acttime': 70, 'actname': 'Opera'}]},
  {'language': 'Italian', 'opera': 'Adriana Lecouvreur', 'totaltime': '2:30', total: 150, 'composer': 'Francesco Cilea', 'times': [{'opera': 'Adriana Lecouvreur', 'acttime': 40, 'y1': 40, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Adriana Lecouvreur', 'acttime': 35, 'y1': 75, 'y0': 40, 'actname': 'Act II'}, {'opera': 'Adriana Lecouvreur', 'acttime': 30, 'y1': 105, 'y0': 75, 'actname': 'Act III'}, {'opera': 'Adriana Lecouvreur', 'acttime': 45, 'y1': 150, 'y0': 105, 'actname': 'Act IV'}]},
  {'language': 'Italian', 'opera': "Agrippina", 'totaltime': '3:15', total: 195, 'composer': 'G. F. Handel', 'times': [{'acttime': 75, 'actname': 'Act I'}, {'acttime': 80, 'actname': 'Act II'}, {'acttime': 40, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Aida', 'totaltime': '2:30', 'composer': 'Giuseppe Verdi', 'total': 150, 'times': [{'opera': 'Aida', 'acttime': 40, 'y1': 40, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Aida', 'acttime': 45, 'y1': 85, 'y0': 40, 'actname': 'Act II'}, {'opera': 'Aida', 'acttime': 65, 'y1': 150, 'y0': 85, 'actname': 'Acts III and IV'}]},
  {'language': 'Various', 'opera': "Akhnaten", 'totaltime': '2:30', total: 150, 'composer': 'Philip Glass', 'times': [{'acttime': 50, 'actname': 'Act I'}, {'acttime': 55, 'actname': 'Act II'}, {'acttime': 45, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'Albert Herring', 'totaltime': '2:10', 'composer': 'Benjamin Britten', 'total': 130, 'times': [{'opera': 'Albert Herring', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Albert Herring', 'acttime': 70, 'y1': 130, 'y0': 60, 'actname': 'Acts II and III'}]},
  {'language': 'English', 'opera': "Amahl and the Night Visitors", 'totaltime': '0:50', total: 50, 'composer': 'Gian Carlo Menotti', 'times': [{'acttime': 50, 'actname': 'Opera'}]},
  {'language': 'English', 'opera': 'Angel\'s Bone', 'totaltime': '1:20', 'composer': 'Du Yun', 'total': 80, 'times': [{'acttime': 80, 'actname': 'Opera'}]},
  {'language': 'Italian', 'opera': 'Anna Bolena', 'totaltime': '2:50', 'composer': 'Gaetano Donizetti', 'total': 170, 'times': [{'opera': 'Anna Bolena', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Anna Bolena', 'acttime': 90, 'y1': 170, 'y0': 80, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Anna Nicole', 'totaltime': '2:00', 'composer': 'Mark Anthony Turnage', 'total': 120, 'times': [{'opera': 'Anna Nicole', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Anna Nicole', 'acttime': 60, 'y1': 120, 'y0': 60, 'actname': 'Act II'}]},
  {'language': 'German', 'opera': 'Ariadne auf Naxos', 'totaltime': '2:10', 'composer': 'Richard Strauss', 'total': 130, 'times': [{'opera': 'Ariadne auf Naxos', 'acttime': 45, 'y1': 45, 'y0': 0, 'actname': 'Prologue'}, {'opera': 'Ariadne auf Naxos', 'acttime': 85, 'y1': 130, 'y0': 45, 'actname': 'Opera'}]},
  {'language': 'Italian', 'opera': "Ariodante", 'totaltime': '3:15', total: 195, 'composer': 'G. F. Handel', 'times': [{'acttime': 70, 'actname': 'Act I'}, {'acttime': 65, 'actname': 'Act II'}, {'acttime': 60, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'Artaxerxes', 'totaltime': '2:20', 'composer': 'Thomas Arne', 'total': 140, 'times': [{'opera': 'Artaxerxes', 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Artaxerxes', 'acttime': 35, 'y1': 90, 'y0': 55, 'actname': 'Act II'}, {'opera': 'Artaxerxes', 'acttime': 50, 'y1': 140, 'y0': 90, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Attila', 'totaltime': '2:00', 'composer': 'Giuseppe Verdi', 'total': 120, 'times': [{'acttime': 75, 'actname': 'Prologue and Act I'}, {'acttime': 25, 'actname': 'Act II'}, {'acttime': 20, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Atys', 'totaltime': '3:05', 'composer': 'Jean-Baptiste Lully', 'total': 185, 'times': [{'opera': 'Atys', 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Prologue and Act I'}, {'opera': 'Atys', 'acttime': 70, 'y1': 125, 'y0': 55, 'actname': 'Acts II and III'}, {'opera': 'Atys', 'acttime': 60, 'y1': 185, 'y0': 125, 'actname': 'Acts IV and V'}]},
  {'language': 'Italian', 'opera': 'Bajazet', 'totaltime': '2:20', 'composer': 'Antonio Vivaldi', 'total': 140, 'times': [{'acttime': 60, 'actname': 'Act I'}, {'acttime': 45, 'actname': 'Act II'}, {'acttime': 35, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Béatrice et Bénédict', 'totaltime': '1:45', 'composer': 'Hector Berlioz', 'total': 105, 'times': [{'acttime': 60, 'actname': 'Act I'}, {'acttime': 45, 'actname': 'Act II'}]},
  {'language': 'Russian', 'opera': "Betrothal in a Monastery", 'totaltime': '2:50', total: 170, 'composer': 'Sergei Prokofiev', 'times': [{'acttime': 90, 'actname': 'Acts I & II'},{'acttime': 80, 'actname': 'Acts III & IV'}]},
  {'language': 'English', 'opera': 'Billy Budd', 'totaltime': '2:40', 'composer': 'Benjamin Britten', 'total': 160, 'times': [{'opera': 'Billy Budd', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Prologue and Act I'}, {'opera': 'Billy Budd', 'acttime': 80, 'y1': 160, 'y0': 80, 'actname': 'Act II and Epilogue'}]},
  {'language': 'Hungarian', 'opera': "Bluebeard's Castle", 'totaltime': '1:05', 'composer': 'Béla Bartók', 'total': 65, 'times': [{'opera': "Bluebeard's Castle", 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Opera'}]},
  {'language': 'Russian', 'opera': "Boris Godunov (1869)", 'totaltime': '2:25', total: 145, 'composer': 'Modest Mussorgsky', 'times': [{'acttime': 145, 'actname': 'Opera'}]},
  {'language': 'Russian', 'opera': "Boris Godunov (Revised)", 'totaltime': '3:25', total: 205, 'composer': 'Modest Mussorgsky', 'times': [{'acttime': 65, 'actname': 'Prologue & Act I'},{'acttime': 35, 'actname': 'Act II'},{'acttime': 45, 'actname': 'Act III'},{'acttime': 60, 'actname': 'Act IV'}]},
  {'language': 'German', 'opera': 'Caligula', 'totaltime': '2:10', 'composer': 'Detlev Glanert', 'total': 130, 'times': [{'opera': 'Caligula', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Caligula', 'acttime': 65, 'y1': 130, 'y0': 65, 'actname': 'Acts III and IV'}]},
  {'language': 'English', 'opera': 'Candide', 'totaltime': '2:40', 'composer': 'Leonard Bernstein', 'total': 160, 'times': [{'opera': 'Candide', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Candide', 'acttime': 85, 'y1': 160, 'y0': 75, 'actname': 'Act II'}]},
  {'language': 'German', 'opera': 'Capriccio', 'totaltime': '2:30', 'composer': 'Richard Strauss', 'total': 150, 'times': [{'opera': 'Capriccio', 'acttime': 150, 'y1': 150, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'French', 'opera': 'Carmen', 'totaltime': '2:35', 'composer': 'Georges Bizet', 'total': 155, 'times': [{'opera': 'Carmen', 'acttime': 95, 'y1': 95, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Carmen', 'acttime': 60, 'y1': 155, 'y0': 95, 'actname': 'Acts III and IV'}]},
  {'language': 'Italian', 'opera': 'Cavalleria rusticana', 'totaltime': '1:10', 'composer': 'Pietro Mascagni', 'total': 70, 'times': [{'opera': 'Cavalleria rusticana', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'French', 'opera': 'Cendrillon', 'totaltime': '2:20', 'composer': 'Jules Massenet', 'total': 140, 'times': [{'opera': 'Cendrillon', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Cendrillon', 'acttime': 65, 'y1': 140, 'y0': 75, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Champion', 'totaltime': '2:30', 'composer': 'Terence Blanchard', 'total': 150, 'times': [{'acttime': 70, 'actname': 'Act I'}, {'acttime': 80, 'actname': 'Act II'}]},
  {'language': 'Russian', 'opera': 'Cherevichki', 'totaltime': '2:20', 'composer': 'Pyotr Ilyich Tchaikovsky', 'total': 140, 'times': [{'opera': 'Cherevichki', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Cherevichki', 'acttime': 60, 'y1': 140, 'y0': 80, 'actname': 'Acts III and IV'}]},
  {'language': 'English', 'opera': 'Clemency', 'totaltime': '0:50', 'composer': 'James MacMillan', 'total': 50, 'times': [{'opera': 'Clemency', 'acttime': 50, 'y1': 50, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Italian', 'opera': 'Cosi fan tutte', 'totaltime': '2:55', 'composer': 'Wolfgang Amadeus Mozart', 'total': 175, 'times': [{'opera': 'Cosi fan tutte', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Cosi fan tutte', 'acttime': 85, 'y1': 175, 'y0': 90, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Curlew River', 'totaltime': '1:15', 'composer': 'Benjamin Britten', 'total': 75, 'times': [{'opera': 'Curlew River', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'French', 'opera': 'Cyrano de Bergerac', 'totaltime': '2:20', 'composer': 'Franco Alfano', 'total': 140, 'times': [{'opera': 'Cyrano de Bergerac', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Cyrano de Bergerac', 'acttime': 60, 'y1': 140, 'y0': 80, 'actname': 'Act II'}]},
  {'language': 'German', 'opera': 'Das Rheingold', 'totaltime': '2:40', 'composer': 'Richard Wagner', 'total': 160, 'times': [{'opera': 'Das Rheingold', 'acttime': 160, 'y1': 160, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'French', 'opera': 'David et Jonathas', 'totaltime': '2:10', 'composer': 'Marc-Antoine Charpentier', 'total': 130, 'times': [{'opera': 'David et Jonathas', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Prologue, Acts I, II and III'}, {'opera': 'David et Jonathas', 'acttime': 50, 'y1': 130, 'y0': 80, 'actname': 'Acts III and IV'}]},
  {'language': 'English', 'opera': "Dead Man Walking", 'totaltime': '2:15', total: 135, 'composer': 'Jake Heggie', 'times': [{'acttime': 80, 'actname': 'Act I'}, {'acttime': 65, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Death in Venice', 'totaltime': '2:25', 'composer': 'Benjamin Britten', 'total': 145, 'times': [{'opera': 'Death in Venice', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Death in Venice', 'acttime': 65, 'y1': 145, 'y0': 80, 'actname': 'Act II'}]},
  {'language': 'German', 'opera': 'Der fliegende Hollander', 'totaltime': '2:20', 'composer': 'Richard Wagner', 'total': 140, 'times': [{'opera': 'Der fliegende Hollander', 'acttime': 140, 'y1': 140, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'German', 'opera': 'Der Rosenkavalier', 'totaltime': '3:25', 'composer': 'Richard Strauss', 'total': 205, 'times': [{'opera': 'Der Rosenkavalier', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Der Rosenkavalier', 'acttime': 60, 'y1': 135, 'y0': 75, 'actname': 'Act II'}, {'opera': 'Der Rosenkavalier', 'acttime': 70, 'y1': 205, 'y0': 135, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Dialogues des Carmélites', 'totaltime': '2:40', 'composer': 'Francis Poulenc', 'total': 160, 'times': [{'opera': 'Dialogues des Carmélites', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Dialogues des Carmélites', 'acttime': 70, 'y1': 160, 'y0': 90, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'Dido and Aeneas', 'totaltime': '1:00', 'composer': 'Henry Purcell', 'total': 60, 'times': [{'opera': 'Dido and Aeneas', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'German', 'opera': 'Die Entführung aus dem Serail', 'totaltime': '2:15', 'composer': 'Wolfgang Amadeus Mozart', 'total': 135, 'times': [{'opera': 'Die Entführung aus dem Serail', 'acttime': 40, 'y1': 40, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Die Entführung aus dem Serail', 'acttime': 60, 'y1': 100, 'y0': 40, 'actname': 'Act II'}, {'opera': 'Die Entführung aus dem Serail', 'acttime': 35, 'y1': 135, 'y0': 100, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': "Die Frau ohne Schatten", 'totaltime': '3:30', total: 210, 'composer': 'Richard Strauss', 'times': [{'acttime': 70, 'actname': 'Act I'}, {'acttime': 70, 'actname': 'Act II'}, {'acttime': 70, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Die Meistersinger von Nürnberg', 'totaltime': '4:35', 'composer': 'Richard Wagner', 'total': 275, 'times': [{'opera': 'Die Meistersinger von Nürnberg', 'acttime': 85, 'y1': 85, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Die Meistersinger von Nürnberg', 'acttime': 65, 'y1': 150, 'y0': 85, 'actname': 'Act II'}, {'opera': 'Die Meistersinger von Nürnberg', 'acttime': 125, 'y1': 275, 'y0': 150, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Die Tote Stadt', 'totaltime': '2:10', 'composer': 'Erich Wolfgang Korngold', 'total': 130, 'times': [{'opera': 'Die Tote Stadt', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Die Tote Stadt', 'acttime': 40, 'y1': 130, 'y0': 90, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Die Walküre', 'totaltime': '3:55', 'composer': 'Richard Wagner', 'total': 235, 'times': [{'opera': 'Die Walküre', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Die Walküre', 'acttime': 95, 'y1': 160, 'y0': 65, 'actname': 'Act II'}, {'opera': 'Die Walküre', 'acttime': 75, 'y1': 235, 'y0': 160, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Die Zauberflöte', 'totaltime': '2:35', 'composer': 'Wolfgang Amadeus Mozart', 'total': 155, 'times': [{'opera': 'Die Zauberflöte', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Die Zauberflöte', 'acttime': 85, 'y1': 155, 'y0': 70, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Doctor Atomic', 'totaltime': '2:40', 'composer': 'John Adams', 'total': 160, 'times': [{'opera': 'Doctor Atomic', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Doctor Atomic', 'acttime': 85, 'y1': 160, 'y0': 75, 'actname': 'Act II'}]},
  {'language': 'French', 'opera': 'Don Carlos', 'totaltime': '3:25', 'composer': 'Giuseppe Verdi', 'total': 205, 'times': [{'opera': 'Don Carlos', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Don Carlos', 'acttime': 35, 'y1': 125, 'y0': 90, 'actname': 'Act III'}, {'opera': 'Don Carlos', 'acttime': 80, 'y1': 205, 'y0': 125, 'actname': 'Acts IV and V'}]},
  {'language': 'Italian', 'opera': 'Don Giovanni', 'totaltime': '2:55', 'composer': 'Wolfgang Amadeus Mozart', 'total': 175, 'times': [{'opera': 'Don Giovanni', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Don Giovanni', 'acttime': 85, 'y1': 175, 'y0': 90, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Don Pasquale', 'totaltime': '2:20', 'composer': 'Gaetano Donizetti', 'total': 140, 'times': [{'opera': 'Don Pasquale', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Don Pasquale', 'acttime': 50, 'y1': 140, 'y0': 90, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'Einstein on the Beach', 'totaltime': '4:15', 'composer': 'Philip Glass', 'total': 255, 'times': [{'opera': 'Einstein on the Beach', 'acttime': 255, 'y1': 255, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'English', 'opera': 'Elegy for Young Lovers', 'totaltime': '2:25', 'composer': 'Hans Werner Henze', 'total': 145, 'times': [{'opera': 'Elegy for Young Lovers', 'acttime': 50, 'y1': 50, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Elegy for Young Lovers', 'acttime': 50, 'y1': 100, 'y0': 50, 'actname': 'Act II'}, {'opera': 'Elegy for Young Lovers', 'acttime': 45, 'y1': 145, 'y0': 100, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Elektra', 'totaltime': '1:50', 'composer': 'Richard Strauss', 'total': 110, 'times': [{'opera': 'Elektra', 'acttime': 110, 'y1': 110, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'English', 'opera': 'Ellen West', 'totaltime': '1:15', 'composer': 'Ricky Ian Gordon', 'total': 75, 'times': [{'acttime': 75, 'actname': 'Opera'}]},
  {'language': 'English', 'opera': 'El Niño', 'totaltime': '2:00', 'composer': 'John Adams', 'total': 120, 'times': [{'acttime': 60, 'actname': 'Part I'}, {'acttime': 60, 'actname': 'Part II'}]},
  {'language': 'Italian', 'opera': "Elisir d'amore", 'totaltime': '2:20', 'composer': 'Gaetano Donizetti', 'total': 140, 'times': [{'opera': "Elisir d'amore", 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': "Elisir d'amore", 'acttime': 65, 'y1': 140, 'y0': 75, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Ernani', 'totaltime': '2:05', total: 125, 'composer': 'Giuseppe Verdi', 'times': [{'acttime': 45, 'actname': 'Act I'}, {'acttime': 35, 'actname': 'Act II'}, {'acttime': 45, 'actname': 'Acts III and IV'}]},
  {'language': 'Russian', 'opera': 'Eugene Onegin', 'totaltime': '2:35', 'composer': 'Pyotr Ilyich Tchaikovsky', 'total': 155, 'times': [{'opera': 'Eugene Onegin', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Eugene Onegin', 'acttime': 45, 'y1': 120, 'y0': 75, 'actname': 'Act II'}, {'opera': 'Eugene Onegin', 'acttime': 35, 'y1': 155, 'y0': 120, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': "Euryanthe", 'totaltime': '2:50', total: 170, 'composer': 'Carl Maria von Weber', 'times': [{'acttime': 115, 'actname': 'Acts I and II'}, {'acttime': 55, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': "Eurydice", 'totaltime': '2:15', total: 135, 'composer': 'Matthew Aucoin', 'times': [{'acttime': 85, 'actname': 'Acts I and II'}, {'acttime': 50, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Falstaff', 'totaltime': '2:20', 'composer': 'Giuseppe Verdi', 'total': 140, 'times': [{'opera': 'Falstaff', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Falstaff', 'acttime': 50, 'y1': 140, 'y0': 90, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Fedora', 'totaltime': '1:55', 'composer': 'Umberto Giordano', 'total': 115, 'times': [{'acttime': 75, 'actname': 'Acts I and II'}, {'acttime': 40, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Fidelio', 'totaltime': '2:20', 'composer': 'Ludwig van Beethoven', 'total': 140, 'times': [{'opera': 'Fidelio', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Fidelio', 'acttime': 60, 'y1': 140, 'y0': 80, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Fire Shut Up in My Bones', 'totaltime': '2:40', total: 160, 'composer': 'Terence Blanchard', 'times': [{'acttime': 75, 'actname': 'Act I'}, {'acttime': 85, 'actname': 'Acts II and III'}]},
  {'language': 'English', 'opera': 'Flight', 'totaltime': '2:20', total: 140, 'composer': 'Jonathan Dove', 'times': [{'acttime': 90, 'actname': 'Acts I and II'}, {'acttime': 50, 'actname': 'Act III'}]},
  {'language': 'Spanish', 'opera': 'Florencia en el Amazonas', 'totaltime': '1:40', total: 100, 'composer': 'Daniel Catán', 'times': [{'acttime': 55, 'actname': 'Act I'}, {'acttime': 45, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Gianni Schicchi', 'totaltime': '1:00', 'composer': 'Giacomo Puccini', 'total': 60, 'times': [{'opera': 'Gianni Schicchi', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Italian', 'opera': 'Giulio Cesare in Egitto', 'totaltime': '3:45', 'composer': 'G. F. Handel', 'total': 225, 'times': [{'opera': 'Giulio Cesare in Egitto', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Giulio Cesare in Egitto', 'acttime': 70, 'y1': 150, 'y0': 80, 'actname': 'Act II'}, {'opera': 'Giulio Cesare in Egitto', 'acttime': 75, 'y1': 225, 'y0': 150, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Gloria von Jaxtberg', 'totaltime': '1:30', 'composer': 'HK Gruber', 'total': 90, 'times': [{'opera': 'Gloria von Jaxtberg', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Acts I and II'}]},
  {'language': 'English', 'opera': 'Gloriana', 'totaltime': '2:40', 'composer': 'Benjamin Britten', 'total': 160, 'times': [{'acttime': 50, 'actname': 'Act I'}, {'acttime': 50, 'actname': 'Act II'}, {'acttime': 60, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Götterdämmerung', 'totaltime': '4:35', 'composer': 'Richard Wagner', 'total': 275, 'times': [{'opera': 'Götterdämmerung', 'acttime': 125, 'y1': 125, 'y0': 0, 'actname': 'Prologue and Act I'}, {'opera': 'Götterdämmerung', 'acttime': 70, 'y1': 195, 'y0': 125, 'actname': 'Act II'}, {'opera': 'Götterdämmerung', 'acttime': 80, 'y1': 275, 'y0': 195, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Guillaume Tell', 'totaltime': '3:35', 'composer': 'Gioachino Rossini', 'total': 215, 'times': [{'opera': 'Guillaume Tell', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Guillaume Tell', 'acttime': 100, 'y1': 180, 'y0': 80, 'actname': 'Acts II and III'}, {'opera': 'Guillaume Tell', 'acttime': 35, 'y1': 215, 'y0': 180, 'actname': 'Act IV'}]},
  {'language': 'English', 'opera': "Hamlet", 'totaltime': '2:45', total: 165, 'composer': 'Brett Dean', 'times': [{'acttime': 105, 'actname': 'Act I'}, {'acttime': 60, 'actname': 'Act II'}]},
  {'language': 'French', 'opera': "Hamlet", 'totaltime': '2:50', total: 170, 'composer': 'Ambroise Thomas', 'times': [{'acttime': 90, 'actname': 'Act I'}, {'acttime': 80, 'actname': 'Act II'}]},
  {'language': 'German', 'opera': 'Hänsel und Gretel', 'totaltime': '1:55', 'composer': 'Engelbert Humperdinck', 'total': 115, 'times': [{'opera': 'Hänsel und Gretel', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Hänsel und Gretel', 'acttime': 50, 'y1': 115, 'y0': 65, 'actname': 'Act II'}]},
  {'language': 'German', 'opera': 'Hin und zurück', 'totaltime': '0:14', 'composer': 'Paul Hindemith', 'total': 14, 'times': [{'opera': 'Hin und zurück', 'acttime': 14, 'y1': 14, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Italian', 'opera': 'Hipermestra', 'totaltime': '2:30', 'composer': 'Francesco Cavalli', 'total': 150, 'times': [{'acttime': 95, 'actname': 'Acts I & II'}, {'acttime': 55, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Hippolyte et Aricie', 'totaltime': '3:05', 'composer': 'Jean-Philippe Rameau', 'total': 185, 'times': [{'acttime': 115, 'actname': 'Prologue, Acts I, II & III'}, {'acttime': 70, 'actname': 'Acts IV & V'}]},
  {'language': 'Italian', 'opera': 'I puritani', 'totaltime': '2:35', 'composer': 'Vincenzo Bellini', 'total': 155, 'times': [{'opera': 'I puritani', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': 'I puritani', 'acttime': 45, 'y1': 120, 'y0': 75, 'actname': 'Act II'}, {'opera': 'I puritani', 'acttime': 35, 'y1': 155, 'y0': 120, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Idomeneo', 'totaltime': '2:45', 'composer': 'Wolfgang Amadeus Mozart', 'total': 165, 'times': [{'opera': 'Idomeneo', 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Idomeneo', 'acttime': 50, 'y1': 105, 'y0': 55, 'actname': 'Act II'}, {'opera': 'Idomeneo', 'acttime': 60, 'y1': 165, 'y0': 105, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Il barbiere di Siviglia', 'totaltime': '2:50', 'composer': 'Gioachino Rossini', 'total': 170, 'times': [{'opera': 'Il barbiere di Siviglia', 'acttime': 100, 'y1': 100, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Il barbiere di Siviglia', 'acttime': 70, 'y1': 170, 'y0': 100, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': "Il Pirata", 'totaltime': '2:30', total: 150, 'composer': 'Vicenzo Bellini', 'times': [{'acttime': 80, 'actname': 'Act I'}, {'acttime': 70, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Il Tabarro', 'totaltime': '0:50', total: 50, 'composer': 'Giacomo Puccini', 'times': [{'acttime': 50,'actname': 'Opera'}]},
  {'language': 'Italian', 'opera': 'Il Trovatore', 'totaltime': '2:25', 'composer': 'Giuseppe Verdi', 'total': 145, 'times': [{'opera': 'Il Trovatore', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Il Trovatore', 'acttime': 70, 'y1': 145, 'y0': 75, 'actname': 'Acts III and IV'}]},
  {'language': 'Italian', 'opera': 'Il turco in Italia', 'totaltime': '2:40', 'composer': 'Gioachino Rossini', 'total': 160, 'times': [{'opera': 'Il turco in Italia', 'acttime': 85, 'y1': 85, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Il turco in Italia', 'acttime': 75, 'y1': 160, 'y0': 85, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': "In the Penal Colony", 'totaltime': '1:20', total: 80, 'composer': 'Philip Glass', 'times': [{'acttime': 80, 'actname': 'Opera'}]},
  {'language': 'English', 'opera': 'Into the Little Hill', 'totaltime': '0:40', 'composer': 'George Benjamin', 'total': 40, 'times': [{'opera': 'Into the Little Hill', 'acttime': 40, 'y1': 40, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Russian', 'opera': 'Iolanta', 'totaltime': '1:40', 'composer': 'Pyotr Ilyich Tchaikovsky', 'total': 100, 'times': [{'opera': 'Iolanta', 'acttime': 100, 'y1': 100, 'y0': 0, 'actname': 'Opera'}]},
  {'language': 'French', 'opera': 'Iphigénie en Aulide', 'totaltime': '2:30', total: 150, 'composer': 'Christoph Willibald Gluck', 'times': [{'acttime': 110, 'actname': 'Acts I & II'}, {'acttime': 40, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Iphigénie en Tauride', 'totaltime': '1:35', 'composer': 'Christoph Willibald Gluck', 'total': 95, 'times': [{'opera': 'Iphigénie en Tauride', 'acttime': 25, 'y1': 25, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Iphigénie en Tauride', 'acttime': 30, 'y1': 55, 'y0': 25, 'actname': 'Act II'}, {'opera': 'Iphigénie en Tauride', 'acttime': 20, 'y1': 75, 'y0': 55, 'actname': 'Act III'}, {'opera': 'Iphigénie en Tauride', 'acttime': 20, 'y1': 95, 'y0': 75, 'actname': 'Act IV'}]},
  {'language': 'Italian', 'opera': "Iris", 'totaltime': '2:10', total: 130, 'composer': 'Pietro Mascagni', 'times': [{'acttime': 50, 'actname': 'Act I'}, {'acttime': 50, 'actname': 'Act II'}, {'acttime': 30, 'actname': 'Act III'}]},
  {'language': 'Czech', 'opera': 'Jenůfa', 'totaltime': '2:05', 'composer': 'Leoš Janáček', 'total': 125, 'times': [{'opera': 'Jenůfa', 'acttime': 45, 'y1': 45, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Jenůfa', 'acttime': 50, 'y1': 95, 'y0': 45, 'actname': 'Act II'}, {'opera': 'Jenůfa', 'acttime': 30, 'y1': 125, 'y0': 95, 'actname': 'Act III'}]},
  {'language': 'Czech', 'opera': 'Káťa Kabanová', 'totaltime': '1:40', 'composer': 'Leoš Janáček', 'total': 100, 'times': [{'opera': 'Káťa Kabanová', 'acttime': 40, 'y1': 40, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Káťa Kabanová', 'acttime': 30, 'y1': 70, 'y0': 40, 'actname': 'Act II'}, {'opera': 'Káťa Kabanová', 'acttime': 30, 'y1': 100, 'y0': 70, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': "L'amour de loin", 'totaltime': '2:10', 'composer': 'Kaija Saariaho', 'total': 130, 'times': [{'opera': "L'amour de loin", 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Acts I, II and III'}, {'opera': "L'amour de loin", 'acttime': 60, 'y1': 130, 'y0': 70, 'actname': 'Acts IV and V'}]},
  {'language': 'French', 'opera': "L'enfant et les sortilèges", 'totaltime': '1:00', 'composer': 'Maurice Ravel', 'total': 60, 'times': [{'acttime': 60, 'actname': 'Opera'}]},
  {'language': 'French', 'opera': "L'Enlèvement d'Europe", 'totaltime': '0:08', 'composer': 'Darius Milhaud', 'total': 8, 'times': [{'opera': "L'Enlèvement d'Europe", 'acttime': 8, 'y1': 8, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'French', 'opera': "L'heure espagnole", 'totaltime': '0:55', 'composer': 'Maurice Ravel', 'total': 55, 'times': [{'opera': "L'heure espagnole", 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Italian', 'opera': "L'incoronazione di Poppea", 'totaltime': '2:20', 'composer': 'Claudio Monteverdi', 'total': 140, 'times': [{'opera': "L'incoronazione di Poppea", 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Act I'}, {'opera': "L'incoronazione di Poppea", 'acttime': 50, 'y1': 140, 'y0': 90, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'La Bohème', 'totaltime': '2:00', 'composer': 'Giacomo Puccini', 'total': 120, 'times': [{'opera': 'La Bohème', 'acttime': 40, 'y1': 40, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La Bohème', 'acttime': 20, 'y1': 60, 'y0': 40, 'actname': 'Act II'}, {'opera': 'La Bohème', 'acttime': 30, 'y1': 90, 'y0': 60, 'actname': 'Act III'}, {'opera': 'La Bohème', 'acttime': 30, 'y1': 120, 'y0': 90, 'actname': 'Act IV'}]},
  {'language': 'Italian', 'opera': 'La Calisto', 'totaltime': '2:30', 'composer': 'Francesco Cavalli', 'total': 150, 'times': [{'opera': 'La Calisto', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La Calisto', 'acttime': 85, 'y1': 150, 'y0': 65, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'La Cenerentola', 'totaltime': '2:50', 'composer': 'Gioachino Rossini', 'total': 170, 'times': [{'opera': 'La Cenerentola', 'acttime': 105, 'y1': 105, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La Cenerentola', 'acttime': 65, 'y1': 170, 'y0': 105, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'La clemenza di Tito', 'totaltime': '2:25', 'composer': 'Wolfgang Amadeus Mozart', 'total': 145, 'times': [{'opera': 'La clemenza di Tito', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La clemenza di Tito', 'acttime': 75, 'y1': 145, 'y0': 70, 'actname': 'Act II'}]},
  {'language': 'French', 'opera': 'La damnation de Faust', 'totaltime': '2:05', 'composer': 'Hector Berlioz', 'total': 125, 'times': [{'opera': 'La damnation de Faust', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La damnation de Faust', 'acttime': 65, 'y1': 125, 'y0': 60, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'La donna del lago', 'totaltime': '2:35', 'composer': 'Gioachino Rossini', 'total': 155, 'times': [{'opera': 'La donna del lago', 'acttime': 95, 'y1': 95, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La donna del lago', 'acttime': 60, 'y1': 155, 'y0': 95, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'La fanciulla del West', 'totaltime': '2:15', 'composer': 'Giacomo Puccini', 'total': 135, 'times': [{'opera': 'La fanciulla del West', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La fanciulla del West', 'acttime': 45, 'y1': 105, 'y0': 60, 'actname': 'Act II'}, {'opera': 'La fanciulla del West', 'acttime': 30, 'y1': 135, 'y0': 105, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'La fille du régiment', 'totaltime': '2:10', 'composer': 'Gaetano Donizetti', 'total': 130, 'times': [{'opera': 'La fille du régiment', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La fille du régiment', 'acttime': 50, 'y1': 130, 'y0': 80, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'La finta giardiniera', 'totaltime': '2:55', 'composer': 'Wolfgang Amadeus Mozart', 'total': 175, 'times': [{'acttime': 75, 'actname': 'Act I'}, {'acttime': 100, 'actname': 'Acts II & III'}]},
  {'language': 'Italian', 'opera': 'La Forza del destino', 'totaltime': '3:00', total: 180, 'composer': 'Giuseppe Verdi', 'times': [{'acttime': 80, 'actname': 'Acts I and II'}, {'acttime': 60, 'actname': 'Act III'}, {'acttime': 40, 'actname': 'Act IV'}]},
  {'language': 'Italian', 'opera': 'La Traviata', 'totaltime': '2:15', 'composer': 'Giuseppe Verdi', 'total': 135, 'times': [{'opera': 'La Traviata', 'acttime': 35, 'y1': 35, 'y0': 0, 'actname': 'Act I'}, {'opera': 'La Traviata', 'acttime': 65, 'y1': 100, 'y0': 35, 'actname': 'Act II'}, {'opera': 'La Traviata', 'acttime': 35, 'y1': 135, 'y0': 100, 'actname': 'Act III'}]},
  {'language': 'Russian', 'opera': 'Lady Macbeth of Mtsensk', 'totaltime': '2:55', 'composer': 'Dmitri Shostakovich', 'total': 175, 'times': [{'acttime': 110, 'actname': 'Acts I & II'}, {'acttime': 65, 'actname': 'Acts II & III'}]},
  {'language': 'French', 'opera': 'Le Comte Ory', 'totaltime': '2:20', 'composer': 'Gioachino Rossini', 'total': 140, 'times': [{'opera': 'Le Comte Ory', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Le Comte Ory', 'acttime': 70, 'y1': 140, 'y0': 70, 'actname': 'Act II'}]},
  {'language': 'Any', 'opera': 'Le Grande Macabre', 'totaltime': '1:50', 'composer': 'György Ligeti', 'total': 110, 'times': [{'opera': 'Le Grande Macabre', 'acttime': 45, 'y1': 45, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Le Grande Macabre', 'acttime': 65, 'y1': 110, 'y0': 45, 'actname': 'Acts III and IV'}]},
  {'language': 'Italian', 'opera': 'Le Nozze di Figaro', 'totaltime': '2:55', 'composer': 'Wolfgang Amadeus Mozart', 'total': 175, 'times': [{'opera': 'Le Nozze di Figaro', 'acttime': 95, 'y1': 95, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Le Nozze di Figaro', 'acttime': 80, 'y1': 175, 'y0': 95, 'actname': 'Acts III and IV'}]},
  {'language': 'French', 'opera': "Les contes d'Hoffmann", 'totaltime': '2:55', 'composer': 'Jacques Offenbach', 'total': 175, 'times': [{'opera': "Les contes d'Hoffmann", 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Prologue and Act I'}, {'opera': "Les contes d'Hoffmann", 'acttime': 35, 'y1': 110, 'y0': 75, 'actname': 'Act II'}, {'opera': "Les contes d'Hoffmann", 'acttime': 65, 'y1': 175, 'y0': 110, 'actname': 'Act III + Epilogue'}]},
  {'language': 'French', 'opera': 'Les pêcheurs de perles', 'totaltime': '1:50', 'composer': 'Georges Bizet', 'total': 110, 'times': [{'opera': 'Les pêcheurs de perles', 'acttime': 45, 'y1': 45, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Les pêcheurs de perles', 'acttime': 35, 'y1': 80, 'y0': 45, 'actname': 'Act II'}, {'opera': 'Les pêcheurs de perles', 'acttime': 30, 'y1': 110, 'y0': 80, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Linda di Chamounix', 'totaltime': '3:00', 'composer': 'Gaetano Donizetti', 'total': 180, 'times': [{'opera': 'Linda di Chamounix', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Linda di Chamounix', 'acttime': 60, 'y1': 130, 'y0': 70, 'actname': 'Act II'}, {'opera': 'Linda di Chamounix', 'acttime': 50, 'y1': 180, 'y0': 130, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Lohengrin', 'totaltime': '3:55', 'composer': 'Richard Wagner', 'total': 235, 'times': [{'opera': 'Lohengrin', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Lohengrin', 'acttime': 85, 'y1': 155, 'y0': 70, 'actname': 'Act II'}, {'opera': 'Lohengrin', 'acttime': 80, 'y1': 235, 'y0': 155, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'Lost Highway', 'totaltime': '1:30', 'composer': 'Olga Neuwirth', 'total': 90, 'times': [{'opera': 'Lost Highway', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Several', 'opera': 'Love and Other Demons', 'totaltime': '2:25', 'composer': 'Péter Eötvös', 'total': 145, 'times': [{'opera': 'Love and Other Demons', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Love and Other Demons', 'acttime': 55, 'y1': 145, 'y0': 90, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Lucia di Lammermoor', 'totaltime': '2:15', 'composer': 'Gaetano Donizetti', 'total': 135, 'times': [{'opera': 'Lucia di Lammermoor', 'acttime': 40, 'y1': 40, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Lucia di Lammermoor', 'acttime': 40, 'y1': 80, 'y0': 40, 'actname': 'Act II'}, {'opera': 'Lucia di Lammermoor', 'acttime': 55, 'y1': 135, 'y0': 80, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Lucrezia Borgia', 'totaltime': '2:25', 'composer': 'Gaetano Donizetti', 'total': 145, 'times': [{'opera': 'Lucrezia Borgia', 'acttime': 40, 'y1': 40, 'y0': 0, 'actname': 'Prologue'}, {'opera': 'Lucrezia Borgia', 'acttime': 50, 'y1': 90, 'y0': 40, 'actname': 'Act I'}, {'opera': 'Lucrezia Borgia', 'acttime': 55, 'y1': 145, 'y0': 90, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Luisa Miller', 'totaltime': '2:35', 'composer': 'Giuseppe Verdi', 'total': 155, 'times': [{'opera': 'Luisa Miller', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Luisa Miller', 'acttime': 45, 'y1': 110, 'y0': 65, 'actname': 'Act II'}, {'opera': 'Luisa Miller', 'acttime': 45, 'y1': 155, 'y0': 110, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Lulu', 'totaltime': '3:00', 'composer': 'Alban Berg', 'total': 180, 'times': [{'opera': 'Lulu', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Lulu', 'acttime': 55, 'y1': 120, 'y0': 65, 'actname': 'Act II'}, {'opera': 'Lulu', 'acttime': 60, 'y1': 180, 'y0': 120, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'L\'Italiana in Algeri', 'totaltime': '2:30', 'composer': 'Gioachino Rossini', 'total': 150, 'times': [{'opera': 'L\xe2\x80\x99Italiana in Algeri', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': 'L\xe2\x80\x99Italiana in Algeri', 'acttime': 75, 'y1': 150, 'y0': 75, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Macbeth', 'totaltime': '2:25', 'composer': 'Giuseppe Verdi', 'total': 145, 'times': [{'opera': 'Macbeth', 'acttime': 50, 'y1': 50, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Macbeth', 'acttime': 30, 'y1': 80, 'y0': 50, 'actname': 'Act II'}, {'opera': 'Macbeth', 'acttime': 65, 'y1': 145, 'y0': 80, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Madama Butterfly', 'totaltime': '2:20', 'composer': 'Giacomo Puccini', 'total': 140, 'times': [{'opera': 'Madama Butterfly', 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Madama Butterfly', 'acttime': 50, 'y1': 105, 'y0': 55, 'actname': 'Act II'}, {'opera': 'Madama Butterfly', 'acttime': 35, 'y1': 140, 'y0': 105, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Manon', 'totaltime': '2:55', 'composer': 'Jules Massenet', 'total': 175, 'times': [{'opera': 'Manon', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Manon', 'acttime': 50, 'y1': 120, 'y0': 70, 'actname': 'Act III'}, {'opera': 'Manon', 'acttime': 55, 'y1': 175, 'y0': 120, 'actname': 'Acts IV and V'}]},
  {'language': 'Italian', 'opera': 'Manon Lescaut', 'totaltime': '2:15', 'composer': 'Giacomo Puccini', 'total': 135, 'times': [{'opera': 'Manon Lescaut', 'acttime': 35, 'y1': 35, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Manon Lescaut', 'acttime': 45, 'y1': 80, 'y0': 35, 'actname': 'Act II'}, {'opera': 'Manon Lescaut', 'acttime': 55, 'y1': 135, 'y0': 80, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Maria Stuarda', 'totaltime': '2:10', 'composer': 'Gaetano Donizetti', 'total': 130, 'times': [{'opera': 'Maria Stuarda', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Maria Stuarda', 'acttime': 60, 'y1': 130, 'y0': 70, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Marnie', 'totaltime': '2:20', 'composer': 'Nico Muhly', 'total': 140, 'times': [{'opera': 'Marnie', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Marnie', 'acttime': 70, 'y1': 140, 'y0': 70, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Matilde di Shabran', 'totaltime': '3:10', 'composer': 'Gioachino Rossini', 'total': 190, 'times': [{'opera': 'Matilde di Shabran', 'acttime': 120, 'y1': 120, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Matilde di Shabran', 'acttime': 70, 'y1': 190, 'y0': 120, 'actname': 'Act II'}]},
  {'language': 'German', 'opera': 'Matsukaze', 'totaltime': '1:20', 'composer': 'Toshio Hosokawa', 'total': 80, 'times': [{'opera': 'Matsukaze', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Russian', 'opera': "Mavra", 'totaltime': '0:30', total: 30, 'composer': 'Igor Stravinsky', 'times': [{'acttime': 30, 'actname': 'Opera'}]},
  {'language': 'Italian', 'opera': "Medea", 'totaltime': '2:20', total: 140, 'composer': 'Luigi Cherubini', 'times': [{'acttime': 105, 'actname': 'Acts I & II'}, {'acttime': 35, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Médée', 'totaltime': '2:45', 'composer': 'Marc-Antoine Charpentier', 'total': 165, 'times': [{'opera': 'Médée', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Médée', 'acttime': 30, 'y1': 110, 'y0': 80, 'actname': 'Act III'}, {'opera': 'Médée', 'acttime': 55, 'y1': 165, 'y0': 110, 'actname': 'Acts IV and V'}]},
  {'language': 'Italian', 'opera': 'Mosè in Egitto', 'totaltime': '2:25', 'composer': 'Gioachino Rossini', 'total': 145, 'times': [{'acttime': 65, 'actname': 'Act I'}, {'acttime': 60, 'actname': 'Act II'}, {'acttime': 20, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': "Nabucco", 'totaltime': '2:15', total: 135, 'composer': 'Giuseppe Verdi', 'times': [{'acttime': 80, 'actname': 'Acts I & II'}, {'acttime': 55, 'actname': 'Acts III & IV'}]},
  {'language': 'English', 'opera': 'Nixon in China', 'totaltime': '2:30', total: 150, 'composer': 'John Adams', 'times': [{'acttime': 85, 'actname': 'Act I'}, {'acttime': 65, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Norma', 'totaltime': '2:35', 'composer': 'Vincenzo Bellini', 'total': 155, 'times': [{'opera': 'Norma', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Norma', 'acttime': 65, 'y1': 155, 'y0': 90, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': "Orfeo ed Euridice", 'totaltime': '1:30', total: 90, 'composer': 'Christoph Willibald Gluck', 'times': [{'acttime': 90, 'actname': 'Opera'}]},
  {'language': 'Italian', 'opera': 'Orlando', 'totaltime': '2:55', 'composer': 'G. F. Handel', 'total': 175, 'times': [{'opera': 'Orlando', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Orlando', 'acttime': 55, 'y1': 120, 'y0': 65, 'actname': 'Act II'}, {'opera': 'Orlando', 'acttime': 55, 'y1': 175, 'y0': 120, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Otello', 'totaltime': '2:45', 'composer': 'Giuseppe Verdi', 'total': 165, 'times': [{'opera': 'Otello', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Otello', 'acttime': 85, 'y1': 165, 'y0': 80, 'actname': 'Acts III and IV'}]},
  {'language': 'English', 'opera': "Owen Wingrave", 'totaltime': '2:00', total: 120, 'composer': 'Benjamin Britten', 'times': [{'acttime': 75, 'actname': 'Act I'}, {'acttime': 45, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Pagliacci', 'totaltime': '1:15', 'composer': 'Ruggero Leoncavallo', 'total': 75, 'times': [{'opera': 'Pagliacci', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'German', 'opera': 'Parsifal', 'totaltime': '4:05', 'composer': 'Richard Wagner', 'total': 245, 'times': [{'opera': 'Parsifal', 'acttime': 105, 'y1': 105, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Parsifal', 'acttime': 65, 'y1': 170, 'y0': 105, 'actname': 'Act II'}, {'opera': 'Parsifal', 'acttime': 75, 'y1': 245, 'y0': 170, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Partenope', 'totaltime': '2:50', 'composer': 'G. F. Handel', 'total': 170, 'times': [{'opera': 'Partenope', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Partenope', 'acttime': 55, 'y1': 120, 'y0': 65, 'actname': 'Act II'}, {'opera': 'Partenope', 'acttime': 50, 'y1': 170, 'y0': 120, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Pelléas et Mélisande', 'totaltime': '2:40', 'composer': 'Claude Debussy', 'total': 160, 'times': [{'opera': 'Pelléas et Mélisande', 'acttime': 30, 'y1': 30, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Pelléas et Mélisande', 'acttime': 30, 'y1': 60, 'y0': 30, 'actname': 'Act II'}, {'opera': 'Pelléas et Mélisande', 'acttime': 35, 'y1': 95, 'y0': 60, 'actname': 'Act III'}, {'opera': 'Pelléas et Mélisande', 'acttime': 40, 'y1': 135, 'y0': 95, 'actname': 'Act IV'}, {'name': '#d0743c', 'opera': 'Pelléas et Mélisande', 'acttime': 25, 'y1': 160, 'y0': 135, 'actname': 'Act V'}]},
  {'language': 'English', 'opera': 'Peter Grimes', 'totaltime': '2:25', 'composer': 'Benjamin Britten', 'total': 145, 'times': [{'opera': 'Peter Grimes', 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Peter Grimes', 'acttime': 50, 'y1': 105, 'y0': 55, 'actname': 'Act II'}, {'opera': 'Peter Grimes', 'acttime': 40, 'y1': 145, 'y0': 105, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': "Pierrot Lunaire", 'totaltime': '0:40', total: 40, 'composer': 'Arnold Schoenberg', 'times': [{'acttime': 40, 'actname': 'Melodrama'}]},
  {'language': 'Italian', 'opera': "Poliuto", 'totaltime': '1:50', total: 110, 'composer': 'Gaetano Donizetti', 'times': [{'acttime': 50, 'actname': 'Act I'},{'acttime': 35, 'actname': 'Act II'}, {'acttime': 25, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': "Porgy and Bess", 'totaltime': '2:50', total: 170, 'composer': 'George Gershwin', 'times': [{'acttime': 95, 'actname': 'Act I'}, {'acttime': 75, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Powder Her Face', 'totaltime': '1:50', 'composer': 'Thomas Ades', 'total': 110, 'times': [{'opera': 'Powder Her Face', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Powder Her Face', 'acttime': 50, 'y1': 110, 'y0': 60, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'P R I S M', 'totaltime': '1:40', 'composer': 'Ellen Reid', 'total': 100, 'times': [{'acttime': 100, 'actname': 'Opera'}]},
  {'language': 'English', 'opera': 'Punch and Judy', 'totaltime': '1:45', 'composer': 'Harrison Birtwistle', 'total': 105, 'times': [{'opera': 'Punch and Judy', 'acttime': 105, 'y1': 105, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Italian', 'opera': 'Radamisto', 'totaltime': '2:40', 'composer': 'G. F. Handel', 'total': 160, 'times': [{'opera': 'Radamisto', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Radamisto', 'acttime': 80, 'y1': 160, 'y0': 80, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Riders to the Sea', 'totaltime': '0:55', 'composer': 'Ralph Vaughan Williams', 'total': 55, 'times': [{'opera': 'Riders to the Sea', 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'Italian', 'opera': 'Rigoletto', 'totaltime': '2:10', 'composer': 'Giuseppe Verdi', 'total': 130, 'times': [{'opera': 'Rigoletto', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Rigoletto', 'acttime': 35, 'y1': 95, 'y0': 60, 'actname': 'Act II'}, {'opera': 'Rigoletto', 'acttime': 35, 'y1': 130, 'y0': 95, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': "Rinaldo", 'totaltime': '2:35', total: 155, 'composer': 'G. F. Handel', 'times': [{'acttime': 115, 'actname': 'Acts I & II'}, {'acttime': 40, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': "Rodelinda", 'totaltime': '2:55', total: 175, 'composer': 'G. F. Handel', 'times': [{'acttime': 60, 'actname': 'Act I'}, {'acttime': 60, 'actname': 'Act II'}, {'acttime': 55, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Roméo et Juliette', 'totaltime': '3:00', 'composer': 'Charles Gounod', 'total': 180, 'times': [{'opera': 'Roméo et Juliette', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'Roméo et Juliette', 'acttime': 40, 'y1': 110, 'y0': 70, 'actname': 'Act III'}, {'opera': 'Roméo et Juliette', 'acttime': 70, 'y1': 180, 'y0': 110, 'actname': 'Acts IV and V'}]},
  {'language': 'Czech', 'opera': "Rusalka", 'totaltime': '2:35', total: 155, 'composer': 'Antonín Dvořák', 'times': [{'acttime': 60, 'actname': 'Act I'}, {'acttime': 45, 'actname': 'Act II'}, {'acttime': 50, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Salome', 'totaltime': '1:40', 'composer': 'Richard Strauss', 'total': 100, 'times': [{'opera': 'Salome', 'acttime': 100, 'y1': 100, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'French', 'opera': "Samson et Dalila", 'totaltime': '2:10', total: 130, 'composer': 'Camille Saint-Saëns', 'times': [{'acttime': 50, 'actname': 'Act I'}, {'acttime': 45, 'actname': 'Act II'}, {'acttime': 35, 'actname': 'Act III'}]},
  {'language': 'Sanskrit', 'opera': 'Satyagraha', 'totaltime': '2:35', 'composer': 'Philip Glass', 'total': 155, 'times': [{'opera': 'Satyagraha', 'acttime': 50, 'y1': 50, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Satyagraha', 'acttime': 50, 'y1': 100, 'y0': 50, 'actname': 'Act II'}, {'opera': 'Satyagraha', 'acttime': 55, 'y1': 155, 'y0': 100, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Semiramide', 'totaltime': '3:30', 'composer': 'Gioachino Rossini', 'total': 210, 'times': [{'opera': 'Semiramide', 'acttime': 120, 'y1': 120, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Semiramide', 'acttime': 90, 'y1': 210, 'y0': 120, 'actname': 'Act II'}]},
  {'language': 'German', 'opera': 'Siegfried', 'totaltime': '4:10', 'composer': 'Richard Wagner', 'total': 250, 'times': [{'opera': 'Siegfried', 'acttime': 85, 'y1': 85, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Siegfried', 'acttime': 80, 'y1': 165, 'y0': 85, 'actname': 'Act II'}, {'opera': 'Siegfried', 'acttime': 85, 'y1': 250, 'y0': 165, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Simon Boccanegra', 'totaltime': '2:10', 'composer': 'Giuseppe Verdi', 'total': 130, 'times': [{'opera': 'Simon Boccanegra', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Prologue and Act I'}, {'opera': 'Simon Boccanegra', 'acttime': 30, 'y1': 105, 'y0': 75, 'actname': 'Act II'}, {'opera': 'Simon Boccanegra', 'acttime': 25, 'y1': 130, 'y0': 105, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Stiffelio', 'totaltime': '2:20', 'composer': 'Giuseppe Verdi', 'total': 140, 'times': [{'opera': 'Stiffelio', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Stiffelio', 'acttime': 80, 'y1': 140, 'y0': 60, 'actname': 'Acts II and III'}]},
  {'language': 'Italian', 'opera': 'Suor Angelica', 'totaltime': '0:55', total: 55, 'composer': 'Giacomo Puccini', 'times': [{'acttime': 55,'actname': 'Opera'}]},
  {'language': 'Italian', 'opera': 'Tamerlano', 'totaltime': '3:40', 'composer': 'G. F. Handel', 'total': 220, 'times': [{'opera': 'Tamerlano', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Tamerlano', 'acttime': 75, 'y1': 145, 'y0': 70, 'actname': 'Act II'}, {'opera': 'Tamerlano', 'acttime': 75, 'y1': 220, 'y0': 145, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Tannhäuser', 'totaltime': '3:30', 'composer': 'Richard Wagner', 'total': 210, 'times': [{'opera': 'Tannhäuser', 'acttime': 75, 'y1': 75, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Tannhäuser', 'acttime': 75, 'y1': 150, 'y0': 75, 'actname': 'Act II'}, {'opera': 'Tannhäuser', 'acttime': 60, 'y1': 210, 'y0': 150, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Thaïs', 'totaltime': '2:20', 'composer': 'Jules Massenet', 'total': 140, 'times': [{'opera': 'Thaïs', 'acttime': 50, 'y1': 50, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Thaïs', 'acttime': 45, 'y1': 95, 'y0': 50, 'actname': 'Act II'}, {'opera': 'Thaïs', 'acttime': 45, 'y1': 140, 'y0': 95, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': "Theodora", 'totaltime': '3:05', total: 185, 'composer': 'G. F. Handel', 'times': [{'acttime': 80, 'actname': 'Act I'}, {'acttime': 50, 'actname': 'Act II'}, {'acttime': 55, 'actname': 'Act III'}]},
  {'language': 'Czech', 'opera': "The Bartered Bride", 'totaltime': '2:25', total: 145, 'composer': 'Bedřich Smetana', 'times': [{'acttime': 100, 'actname': 'Acts I & II'}, {'acttime': 55, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': "The Beggar's Opera", 'totaltime': '2:25', 'composer': 'John Gay', 'total': 145, 'times': [{'opera': "The Beggar's Opera", 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': "The Beggar's Opera", 'acttime': 80, 'y1': 145, 'y0': 65, 'actname': 'Acts II and III'}]},
  {'language': 'Italian', 'opera': 'The Capulets and the Montagues', 'totaltime': '2:30', 'composer': 'Vincenzo Bellini', 'total': 150, 'times': [{'opera': 'The Capulets and the Montagues', 'acttime': 90, 'y1': 90, 'y0': 0, 'actname': 'Act I'}, {'opera': 'The Capulets and the Montagues', 'acttime': 60, 'y1': 150, 'y0': 90, 'actname': 'Act II'}]},
  {'language': 'Czech', 'opera': 'The Cunning Little Vixen', 'totaltime': '1:45', 'composer': 'Leoš Janáček', 'total': 105, 'times': [{'opera': 'The Cunning Little Vixen', 'acttime': 30, 'y1': 30, 'y0': 0, 'actname': 'Act I'}, {'opera': 'The Cunning Little Vixen', 'acttime': 40, 'y1': 70, 'y0': 30, 'actname': 'Act II'}, {'opera': 'The Cunning Little Vixen', 'acttime': 35, 'y1': 105, 'y0': 70, 'actname': 'Act III'}]},
  {'language': 'Russian', 'opera': 'The Enchanted Wanderer', 'totaltime': '1:40', 'composer': 'Rodion Shchedrin', 'total': 100, 'times': [{'opera': 'The Enchanted Wanderer', 'acttime': 100, 'y1': 100, 'y0': 0, 'actname': 'Opera'}]},
  {'language': 'English', 'opera': 'The Exterminating Angel', 'totaltime': '2:15', 'composer': 'Thomas Adès', 'total': 135, 'times': [{'opera': 'The Exterminating Angel', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'The Exterminating Angel', 'acttime': 55, 'y1': 135, 'y0': 80, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'The Fairy-Queen', 'totaltime': '3:10', 'composer': 'Henry Purcell', 'total': 190, 'times': [{'opera': 'The Fairy-Queen', 'acttime': 100, 'y1': 100, 'y0': 0, 'actname': 'Acts I, II and III'}, {'opera': 'The Fairy-Queen', 'acttime': 90, 'y1': 190, 'y0': 100, 'actname': 'Acts IV and V'}]},
  {'language': 'Russian', 'opera': 'The Gambler', 'totaltime': '2:10', 'composer': 'Sergei Prokofiev', 'total': 130, 'times': [{'opera': 'The Gambler', 'acttime': 25, 'y1': 25, 'y0': 0, 'actname': 'Act I'}, {'opera': 'The Gambler', 'acttime': 35, 'y1': 60, 'y0': 25, 'actname': 'Act II'}, {'opera': 'The Gambler', 'acttime': 30, 'y1': 90, 'y0': 60, 'actname': 'Act III'}, {'opera': 'The Gambler', 'acttime': 40, 'y1': 130, 'y0': 90, 'actname': 'Act IV'}]},
  {'language': 'English', 'opera': 'The Hours', 'totaltime': '2:25', 'composer': 'Kevin Puts', 'total': 130, 'times': [{'acttime': 85, 'actname': 'Act I'}, {'acttime': 60, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'The Importance of Being Earnest', 'totaltime': '1:30', 'composer': 'Gerald Barry', 'total': 90, 'times': [{'opera': 'The Importance of Being Earnest', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Acts I and II'}, {'opera': 'The Importance of Being Earnest', 'acttime': 30, 'y1': 90, 'y0': 60, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'The Last Supper', 'totaltime': '2:00', 'composer': 'Harrison Birtwistle', 'total': 120, 'times': [{'acttime': 120, 'actname': 'Opera'}]},
  {'language': 'Czech', 'opera': 'The Makropulos Case', 'totaltime': '1:35', 'composer': 'Leoš Janáček', 'total': 95, 'times': [{'opera': 'The Makropulos Case', 'acttime': 35, 'y1': 35, 'y0': 0, 'actname': 'Act I'}, {'opera': 'The Makropulos Case', 'acttime': 30, 'y1': 65, 'y0': 35, 'actname': 'Act II'}, {'opera': 'The Makropulos Case', 'acttime': 30, 'y1': 95, 'y0': 65, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'The Mikado', 'totaltime': '2:10', 'composer': 'Arthur Sullivan', 'total': 130, 'times': [{'opera': 'The Mikado', 'acttime': 70, 'y1': 70, 'y0': 0, 'actname': 'Act I'}, {'opera': 'The Mikado', 'acttime': 60, 'y1': 130, 'y0': 70, 'actname': 'Act II'}]},
  {'language': 'Russian', 'opera': 'The Miserly Knight', 'totaltime': '1:10', total: 70, 'composer': 'Sergei Rachmaninoff', 'times': [{'acttime': 70, 'actname': 'Opera'}]},
  {'language': 'English', 'opera': 'The Minotaur', 'totaltime': '2:20', 'composer': 'Harrison Birtwistle', 'total': 140, 'times': [{'opera': 'The Minotaur', 'acttime': 85, 'y1': 85, 'y0': 0, 'actname': 'Act I'}, {'opera': 'The Minotaur', 'acttime': 55, 'y1': 140, 'y0': 85, 'actname': 'Act II'}]},
  {'language': 'Russian', 'opera': 'The Nose', 'totaltime': '1:50', 'composer': 'Dmitri Shostakovich', 'total': 110, 'times': [{'opera': 'The Nose', 'acttime': 110, 'y1': 110, 'y0': 0, 'actname': 'Acts I, II & III'}]},
  {'language': 'Russain', 'opera': "The Queen of Spades", 'totaltime': '3:05', total: 185, 'composer': 'Pyotr Ilyich Tchaikovsky', 'times': [{'acttime': 70, 'actname': 'Act I'}, {'acttime': 115, 'actname': 'Acts II & III'}]},
  {'language': 'English', 'opera': "The Rake's Progress", 'totaltime': '2:45', total: 165, 'composer': 'Igor Stravinsky', 'times': [{'acttime': 80, 'actname': 'Act I'}, {'acttime': 85, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'The Rape of Lucretia', 'totaltime': '1:55', 'composer': 'Benjamin Britten', 'total': 115, 'times': [{'opera': 'The Rape of Lucretia', 'acttime': 60, 'y1': 60, 'y0': 0, 'actname': 'Prologue & Act I'}, {'opera': 'The Rape of Lucretia', 'acttime': 55, 'y1': 115, 'y0': 60, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'The Raven', 'totaltime': '0:50', 'composer': 'Toshio Hosokawa', 'total': 50, 'times': [{'opera': 'The Raven', 'acttime': 50, 'y1': 50, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'English', 'opera': 'The Tempest', 'totaltime': '2:10', 'composer': 'Thomas Ades', 'total': 130, 'times': [{'opera': 'The Tempest', 'acttime': 85, 'y1': 85, 'y0': 0, 'actname': 'Acts I & II'}, {'opera': 'The Tempest', 'acttime': 45, 'y1': 130, 'y0': 85, 'actname': 'Act III'}]},
  {'language': 'Russian', 'opera': "The Tsar's Bride", 'totaltime': '2:35', 'composer': 'Nikolai Rimsky-Korsakov', 'total': 155, 'times': [{'opera': "The Tsar's Bride", 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Act I'}, {'opera': "The Tsar's Bride", 'acttime': 35, 'y1': 90, 'y0': 55, 'actname': 'Act II'}, {'opera': "The Tsar's Bride", 'acttime': 30, 'y1': 120, 'y0': 90, 'actname': 'Act III'}, {'opera': "The Tsar's Bride", 'acttime': 35, 'y1': 155, 'y0': 120, 'actname': 'Act IV'}]},
  {'language': 'English', 'opera': 'The Turn of the Screw', 'totaltime': '1:40', 'composer': 'Benjamin Britten', 'total': 100, 'times': [{'opera': 'The Turn of the Screw', 'acttime': 50, 'y1': 50, 'y0': 0, 'actname': 'Act I'}, {'opera': 'The Turn of the Screw', 'acttime': 50, 'y1': 100, 'y0': 50, 'actname': 'Act II'}]},
  {'language': 'English', 'opera': 'Thebans', 'totaltime': '1:40', 'composer': 'Julian Anderson', 'total': 100, 'times': [{'opera': 'Thebans', 'acttime': 50, 'y1': 50, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Thebans', 'acttime': 20, 'y1': 70, 'y0': 50, 'actname': 'Act II'}, {'opera': 'Thebans', 'acttime': 30, 'y1': 100, 'y0': 70, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Tosca', 'totaltime': '1:55', 'composer': 'Giacomo Puccini', 'total': 115, 'times': [{'opera': 'Tosca', 'acttime': 45, 'y1': 45, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Tosca', 'acttime': 40, 'y1': 85, 'y0': 45, 'actname': 'Act II'}, {'opera': 'Tosca', 'acttime': 30, 'y1': 115, 'y0': 85, 'actname': 'Act III'}]},
  {'language': 'German', 'opera': 'Tristan und Isolde', 'totaltime': '3:55', 'composer': 'Richard Wagner', 'total': 235, 'times': [{'opera': 'Tristan und Isolde', 'acttime': 80, 'y1': 80, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Tristan und Isolde', 'acttime': 75, 'y1': 155, 'y0': 80, 'actname': 'Act II'}, {'opera': 'Tristan und Isolde', 'acttime': 80, 'y1': 235, 'y0': 155, 'actname': 'Act III'}]},
  {'language': 'Italian', 'opera': 'Turandot', 'totaltime': '2:05', 'composer': 'Giacomo Puccini', 'total': 125, 'times': [{'opera': 'Turandot', 'acttime': 35, 'y1': 35, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Turandot', 'acttime': 50, 'y1': 85, 'y0': 35, 'actname': 'Act II'}, {'opera': 'Turandot', 'acttime': 40, 'y1': 125, 'y0': 85, 'actname': 'Act III'}]},
  {'language': 'English', 'opera': 'Two Boys', 'totaltime': '1:50', 'composer': 'Nico Muhly', 'total': 110, 'times': [{'opera': 'Two Boys', 'acttime': 65, 'y1': 65, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Two Boys', 'acttime': 45, 'y1': 110, 'y0': 65, 'actname': 'Act II'}]},
  {'language': 'Italian', 'opera': 'Un Ballo in Maschera', 'totaltime': '2:25', 'composer': 'Giuseppe Verdi', 'total': 145, 'times': [{'opera': 'Un Ballo in Maschera', 'acttime': 55, 'y1': 55, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Un Ballo in Maschera', 'acttime': 35, 'y1': 90, 'y0': 55, 'actname': 'Act II'}, {'opera': 'Un Ballo in Maschera', 'acttime': 55, 'y1': 145, 'y0': 90, 'actname': 'Act III'}]},
  {'language': 'French', 'opera': 'Werther', 'totaltime': '2:20', 'composer': 'Jules Massenet', 'total': 140, 'times': [{'opera': 'Werther', 'acttime': 45, 'y1': 45, 'y0': 0, 'actname': 'Act I'}, {'opera': 'Werther', 'acttime': 35, 'y1': 80, 'y0': 45, 'actname': 'Act II'}, {'opera': 'Werther', 'acttime': 60, 'y1': 140, 'y0': 80, 'actname': 'Acts III & IV'}]},
  {'language': 'German', 'opera': 'Wozzeck', 'totaltime': '1:40', 'composer': 'Alban Berg', 'total': 100, 'times': [{'opera': 'Wozzeck', 'acttime': 100, 'y1': 100, 'y0': 0, 'actname': 'Acts I, II & III'}]},
  {'language': 'English', 'opera': 'Written on Skin', 'totaltime': '1:35', 'composer': 'George Benjamin', 'total': 95, 'times': [{'opera': 'Written on Skin', 'acttime': 95, 'y1': 95, 'y0': 0, 'actname': 'Act I'}]},
  {'language': 'English', 'opera': 'X: The Life and Times of Malcolm X', 'totaltime': '2:30', 'composer': 'Anthony Davis', 'total': 150, 'times': [{'acttime': 55, 'actname': 'Act I'}, {'acttime': 45, 'actname': 'Act II'},{'acttime': 50, 'actname': 'Act III'}]}
];


// markup
const RunningPage = () => {
  const [opera, setOpera] = React.useState('');
  const [foundOperas, setFoundOperas] = React.useState(OPERAS);
  const [currentSort, setCurrentSort] = React.useState('Opera');

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = OPERAS.filter((opera) => {
        return opera.opera.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(keyword.toLowerCase()) || opera.composer.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundOperas(sort(results));
    } else {
      setFoundOperas(sort(OPERAS));
    }

    setOpera(keyword);
  };

  const setSort = (e) => {
    setCurrentSort(e.target.value);
    setFoundOperas(sort(foundOperas, e.target.value));
  }

  const sort = (sortableOperas, sortingFlag = currentSort) => {  
    return sortableOperas.sort((a, b) => {
      if(a[sortingFlag] < b[sortingFlag]) { return -1; }
      if(a[sortingFlag] > b[sortingFlag]) { return 1; }
      if(a['opera'] < b['opera']) { return -1; }
      if(a['opera'] > b['opera']) { return 1; }
      return 0;
    });
  };

  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Opera Running Times | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operaabc/runningtimes" />
          <meta name="description" content="How long is that opera? Act by Act running times for operas by Verdi, Wagner, Britten, Rossini, Handel and many, many more." />
        </Helmet>
        <Hero title="Running Times" subtitle="Are all operas long?"/>
        <MainBodySection>
        
          <p>Some operas are very long, and some are very short - the shortest we know of, <i>L'Enlèvement d'Europe</i>, is a mere 8 minutes! Our list currently has over 200 operas - a good deal of the standard repertory plus a collection of assorted rarities.</p>

          <p>You should take these running times with a small pinch of salt. Music can be cut, Conductors vary in their tempi, and you'll need to factor in intervals/intermissions (both number of and duration) to determine how long you'll be at the theatre. In most cases, we give the running times on an Act by Act basis except in cases where the Acts are conventionally run together (anyone who sticks a break between Acts III and IV of Aida should be shot). We've also tended towards longer running times rather than shorter: leaving the theatre earlier than expected is pleasant whilst leaving late means missing the train...</p>

        </MainBodySection>

        
        <div id="ezoic-pub-ad-placeholder-102"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

          <FilterGroup>
            <FilterSection>
              <div>Search</div>
              <input
                type=""
                value={opera}
                onChange={filter}
              />
            </FilterSection>

            <FilterSection>
              <div>Sort</div>
              <select onChange={setSort} name="sort">
                <option value="opera">Opera</option>
                <option value="composer">Composer</option>
                <option value="total">Length</option>
              </select>
            </FilterSection>
          </FilterGroup>

          <TopLevelGrid>
            <div><h3>Opera & Composer</h3></div>
            <div><h3>Running Time</h3></div>
            {foundOperas.map(d => (
            <>
              <OperaGeneral>
                <h4>{d.opera}</h4>
                <p>{d.composer}</p>
              </OperaGeneral>
              <Acts>
                <h4>{(d.totaltime.split(":")[0] > 0) && d.totaltime.split(":")[0]+' hour'}{(d.totaltime.split(":")[0] > 1) && 's'} {(d.totaltime.split(":")[0] > 0) && (d.totaltime.split(":")[1] > 0) && 'and'} {(d.totaltime.split(":")[1] > 0) && parseInt(d.totaltime.split(":")[1])+' minutes'}</h4>
                {d.times.length > 1 && d.times.map((act, index) => (
                  <span id={index}>
                    <p>{act.acttime} mins</p>
                    <p>{act.actname}</p>
                  </span>
                ))}
              </Acts>
            </>
            ))}
          </TopLevelGrid>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

    </Layout>
  )
}

export default RunningPage
